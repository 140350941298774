ion-toolbar.no-color {
  --background: var(--ion-background-color);
}

ion-toolbar:has(ion-buttons) {
  --min-height: 90px;
  ion-buttons[slot="end"] {
    margin-right: var(--ion-margin);
  }
}

ion-toolbar {
  .toolbar-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    ion-button {
      min-height: 50px;
      min-width: 150px;
    }
  }
}
