ion-accordion-group {
  //padding: var(--ion-margin);
  border-radius: var(--app-border-radius);
  --background: var(--ion-item-background);
}

ion-accordion {
  ion-item {
    --background: var(--ion-item-background);
    --ion-color-base: var(--ion-item-background) !important;
  }
}

ion-accordion-group.accordion-group-expand-inset > ion-accordion:first-of-type {
  border-top-left-radius: var(--app-border-radius);
  border-top-right-radius: var(--app-border-radius);
}

ion-accordion-group.accordion-group-expand-inset > ion-accordion:last-of-type {
  border-bottom-left-radius: var(--app-border-radius);
  border-bottom-right-radius: var(--app-border-radius);
}

ion-accordion-group.accordion-group-expand-inset > ion-accordion {
  box-shadow: none !important;
}

ion-accordion.accordion-expanded div[slot="content"] {
  background: var(--ion-item-background); // var(--ion-item-background);
}

ion-accordion.accordion-collapsing ion-item[slot='header'],
ion-accordion.accordion-collapsed ion-item[slot='header'] {
  --background: var(--ion-item-background);
  --color: var(--ion-color-light-contrast);
}
