.md ion-segment {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: var(--ion-margin);

  @media (min-width: 768px) {
    align-items: center;
    justify-content: center;
  }
}

.md ion-segment ion-label {
  text-transform: capitalize;
}

/* Material Design styles */
ion-segment-button.md {
  //--color: #000;
  //--color-checked: #08a391;
  --indicator-height: 5px;
  --ripple-color: transparent;
}

ion-segment-button.md::part(indicator) {
  padding-left: var(--ion-margin);
  padding-right: var(--ion-margin);
  border-radius: 50%;
}
