.narrow {
  padding: 4px 8px;
  margin: 2px 0;
  min-height: auto;
  &.medium {
    color: var(--ion-color-medium-contrast);
    background: var(--ion-color-medium-shade);
  }
  &.ACTIVE {
    text-transform: lowercase;
    background: var(--ion-color-success-shade);
    color: var(--ion-color-success-contrast);
  }
  &.INACTIVE {
    text-transform: lowercase;
    background: var(--ion-color-medium-shade);
    color: var(--ion-color-success-contrast);
  }
}
