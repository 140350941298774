ion-modal {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.65);
  backdrop-filter: blur(3px);

  @media (min-width: 768px) {
    --border-radius: var(--app-border-radius) !important;
  }
}

ion-modal.form {
  @media (min-width: 768px) {

    //--width: 80vw;
    --height: 90vh;
    ion-footer ion-button {
      max-width: 200px;
    }
  }
}

ion-modal.confirm {
  --height: auto;
  --width: 90vw;
  --border-radius: var(--app-border-radius) !important;
  @media (min-width: 768px) {
    --max-width: 360px;
    padding-left: var(--app-side-menu-width);
  }
}

ion-modal.bottom-form, ion-modal.bottom-filter-form {
  //--height: 90vh;
  --width: 100vw;
  --height: auto;
  //--max-height: 450px;
  align-items: flex-end; // center; //
  justify-content: center;
  --border-radius: var(--app-border-radius) var(--app-border-radius) 0 0 !important;

  @media (min-width: 768px) {
    --max-width: 360px;
    --max-height: 500px;
    align-items: center; //
    justify-content: center;
    --border-radius: var(--app-border-radius) var(--app-border-radius) !important;
  }
}

ion-modal.bottom-filter-form {
  --height: calc(100% - 3rem);
}
