.md ion-button {
  --box-shadow: none !important;
  //min-height: 3.1em !important;
  text-transform: capitalize;
  font-size: min(1rem, 48px);
  font-weight: 500;
  letter-spacing: 0px;
  &.with-shadow {
    --box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
  }
}

ion-icon.background-primary {
  background: var(--ion-color-primary-tint);
  --color: var(--ion-color-primary-contrast);
  border-radius: 50%;
  padding: 6px;
  margin-top: 6px;
  margin-bottom: 6px;
}

ion-button.icon-button {
  --size: 32px;
  width: 54px;
  height: 54px;
  --border-radius: 16px;
  ion-icon {
    width: var(--size);
    height: var(--size);
    min-height: var(--size);
    min-width: var(--size);
  }
}

ion-back-button, ion-toolbar .custom-button {
  --color: var(--ion-color-primary);
  --background: var(--ion-color-secondary-tint);
  --border-radius: 15px !important; //var(--app-border-radius);
  --min-width: 54px;
  --min-height: 54px;
  --width: 54px;
  --height: 54px;
  width: 54px;
  height: 54px;
  --margin-start: var(--ion-margin);
  &::part(text) {
    display: none;
  }
  &::part(icon) {
    color: var(--ion-color-primary);
  }
}

ion-segment.custom {
  gap: 4px;
  ion-segment-button {
    --background: var(--ion-color-primary-tint);
    --background-checked: var(--ion-color-primary);
    --border-radius: var(--app-border-radius);
    --color: var(--ion-color-primary);
    --color-checked: var(--ion-color-primary-contrast);
    --indicator-height: 0px;
    min-height: 38px;

    &.segment-button-has-label-only ion-label {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
}

ion-button.with-badge {
  &::part(native) {
    overflow:visible
  }
  ion-badge {
    aspect-ratio:1/1;
    border-radius:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    font-size:0.8em;
    height:30px;
    width:30px;
    right:-20px;
    position:absolute;
    top:-15px
  }
}
