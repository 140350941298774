ion-card {
  border-radius: var(--app-border-radius);
  box-shadow: none;
  padding: 12px 12px;

  ion-item {
    --background: none;
  }
}
ion-card.icon-card {
  h3 {
    margin: auto 0;
  }
  div.icon {
    font-size: xx-large;
    background: beige;
    border-radius: 50%;
    padding: 4px;
    width: 50px;
    height: 50px;
    text-align: center;
    margin-inline-end: 1rem;
    p {
      margin: 0;
    }
  }
  div.data {
    width: calc(50px + 1.5rem);
    margin-inline-end: 0.5rem;
  }
}
ion-card:has(ion-list) {
  margin: 0;
  ion-list, ion-item {
    --background: none;
    background: none !important;
  }
}

.title-with-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

ion-card.base {
  --background: var(--ion-color-tertiary-tint);
  --icon-background: var(--ion-color-tertiary-shade);
  margin: 0;
  padding: 20px 12px;

  .emoji {
    background: var(--icon-background);
    margin-right: var(--ion-margin);
    font-size: xx-large;
    border-radius: 50%;
    padding: 4px;
    width: 50px;
    height: 50px;
    text-align: center;
    p {
      margin: 0;
      font-size: xx-large;
    }
  }

  h2 {
    font-weight: 500 !important;
    font-size: large !important;
    margin-bottom: 8px !important;
  }
  p {
    font-size: medium;
  }

  &.pink {
    --background: var(--ion-color-danger-tint);
    --icon-background: var(--ion-color-danger-shade);
  }
  &.light {
    --background: var(--ion-color-light-tint);
    --icon-background: var(--ion-color-light-shade);
  }
  &.secondary {
    --background: var(--ion-color-secondary-tint);
    --icon-background: var(--ion-color-secondary-shade);
  }
  &.success {
    --background: var(--ion-color-success-tint);
    --icon-background: var(--ion-color-success-shade);
  }
  &.warning {
    --background: var(--ion-color-warning-tint);
    --icon-background: var(--ion-color-warning-shade);
  }
  &.tertiary {
    --background: var(--ion-color-tertiary-tint);
    --icon-background: var(--ion-color-tertiary-shade);
  }
}

ion-card ion-card-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

