ngx-datatable {
  box-shadow: none !important;
  font-family: var(--app-font-family);
  border-radius: var(--app-border-radius);
  font-size: 12px !important;
}

.ngx-datatable.material,
.ngx-datatable.material .datatable-header .datatable-header-cell {
  background-color: var(--ion-item-background) !important;
  color: var(--ion-text-color) !important;
  font-size: var(--app-font-size) !important;
}

.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
  font-size: var(--app-font-size) !important;
  color: var(--ion-text-color) !important;
}

.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background-color: var(--ion-color-light-tint) !important;
  cursor: pointer;
}

.ngx-datatable.material.single-selection .datatable-body-row.active,
.ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group,
.ngx-datatable.material.multi-selection .datatable-body-row.active,
.ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-row-group,
.ngx-datatable.material.multi-click-selection .datatable-body-row.active,
.ngx-datatable.material.multi-click-selection .datatable-body-row.active .datatable-row-group {
  background-color: var(--ion-color-light-tint) !important;
  color: var(--ion-color-light-contrast) !important;
}

ngx-datatable.scroll-vertical {
  //min-height: 100vh;
}

ngx-datatable .datatable-body-cell-label {
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
