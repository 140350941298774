ion-toast {
  @media (max-width: 768px) {
    --border-radius: var(--app-border-radius);
    --background: var(--ion-item-background);
    --color: var(--ion-text-color);
    &::part(container) {

    }
    &::part(icon) {
      color: var(--ion-color-primary);
    }
  }
}
