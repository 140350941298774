ion-input.custom, ion-textarea.custom, ion-select.custom {
  --background: var(--ion-color-light-tint);
  --color:  var(--ion-color-light-contrast);
  --border-color: var(--ion-color-light-shade);
  --border-width: var(--app-field-border-width);
  --highlight-color-focus: var(--ion-color-primary) !important;
  --placeholder-color: var(--ion-color-light-shade);
  --placeholder-opacity: 0.8;

  --padding-bottom: 10px !important;
  --padding-end: var(--ion-margin) !important;
  --padding-start: var(--ion-margin) !important;
  --padding-top: 10px !important;

  --border-radius: var(--app-border-radius) !important;

  .input-wrapper, .textarea-wrapper, .select-wrapper {
    --padding-start: var(--ion-margin);
    --padding-end: var(--ion-margin);

    .input-outline-start, .textarea-outline-start, .select-outline-start {
      width: var(--app-border-radius);
      min-width: var(--app-border-radius);
    }
  }

  &.with-start-icon .input-wrapper{
    --padding-start: 88px;
  }

  &.ion-touched.ion-invalid {
    --highlight-color-invalid: var(--ion-color-danger-form);
    .input-outline-start, .input-outline-notch, .input-outline-end {
      --border-color: var(--highlight-color-invalid);
    }
    .input-bottom, .textarea-bottom {
      padding-right: 0;
      justify-content: flex-end;
      .error-text {
        color: var(--ion-color-danger-form);
      }
    }
  }

}

ng-otp-input .app {
  background: var(--ion-color-light-tint);
  //border-color: var(--ion-color-light-shade);
  border: var(--ion-color-light-shade) solid 0 !important;
  font-size: large;
  //line-height: 40px;
  padding: 10px 4px;
  font-weight: normal;
  height: 45px !important;
  width: 40px !important;
  border-radius: 5px !important;
  &:focus {
    outline: none !important;
    border: var(--ion-color-primary) solid 0;
  }
}

ng-otp-input {
  &.error input {
    border: var(--ion-color-danger-shade) solid 2px !important;
  }
  &.success input {
    border: var(--ion-color-success-shade) solid 2px !important;
  }
}

.input-label-placement-stacked.sc-ion-input-ios-h input.sc-ion-input-ios,
.input-label-placement-floating.sc-ion-input-ios-h input.sc-ion-input-ios {
  margin-top: 0 !important;
}

ion-select::part(container) {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .sc-ion-alert-md-h {
    padding-left: var(--app-side-menu-width);
  }
}

ion-radio.ios::part(container) {
  width: 20px;
  height: 20px;
  border: 2px solid var(--ion-color-medium-tint);
  border-radius: 4px;
}

.radio-checked.ios::part(container) {
  border-color: var(--ion-color-primary);
}
