ion-footer .row {
  display: grid;
  grid-template-columns: repeat(2, minmax(auto, 200px));
  justify-content: center;
  grid-gap: 2rem;
  ion-button {
    min-height: 48px;
  }
}

ion-footer .loading {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 48px;
}
