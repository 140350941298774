// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  //--ion-font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
  /** primary **/
  --ion-color-primary: #528DFF; //#3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #EEF4FF; // #DCE8FF;//#4c8dff;

  /** secondary **/
  --ion-color-secondary: #DCE8FF; // Blue/20
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #528DFF;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #CBDDFF; // Blue/30
  --ion-color-secondary-tint: #EEF4FF; // Blue/10

  /** tertiary **/
  --ion-color-tertiary: #CEDADA; //Green/20
  --ion-color-tertiary-rgb: 106,100,255;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0,0,0;
  --ion-color-tertiary-shade: #B6C8C8; //Green/30
  --ion-color-tertiary-tint: #E6EDED; //Green/10

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #FFEECC; //ORANGE/20
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #FFE5B3; //ORANGE-30
  --ion-color-warning-tint: #FFF6E6; //ORANGE-10

  /** danger **/
  --ion-color-danger: #FDDBE9; // PINK/20
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #FCC9DE; // PINK/30
  --ion-color-danger-tint: #FEEDF4; // PINK/10
  --ion-color-danger-form: #F54A92; // PINK/1000

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-toolbar-background: #ffffff;
  --ion-item-background: #ffffff;
  --ion-background-color: #ffffff; //#fafafa; //#ffffff;
}

@media (prefers-color-scheme: --dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    --ion-color-primary: #155de5; // #428cff
    --ion-color-primary-rgb: 66,140,255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #383a3e;

    --ion-color-secondary: #75A4FF; // BLUE/80
    --ion-color-secondary-rgb: 80,200,255;
    --ion-color-secondary-contrast: #DCE8FF;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #528DFF; // BLUE/100
    --ion-color-secondary-tint: #75A4FF; // BLUE/80 #97BBFF; // BLUE/60

    --ion-color-tertiary: #3B6D6D; //Green/80
    --ion-color-tertiary-rgb: 106,100,255;
    --ion-color-tertiary-contrast: #000000;
    --ion-color-tertiary-contrast-rgb: 0,0,0;
    --ion-color-tertiary-shade: #0A4848; //Green/100
    --ion-color-tertiary-tint: #3B6D6D; //Green/80

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47,223,117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255,213,52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #F76FA8; // PINK/80
    --ion-color-danger-rgb: 255,73,97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #F54A92; // PINK/100
    --ion-color-danger-tint: #F76FA8; // PINK/80

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;

    --ion-toolbar-background: #000000;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
}

:root {
  --app-border-radius: 24px;
  --app-side-menu-width: 250px;
  --app-font-family: "SF Pro Display","SF Pro Text",sans-serif;
  --app-font-size: 16px;
  --app-field-border-width: 1px;

  --ion-font-family: var(--app-font-family);
  --ion-dynamic-font: var(--ion-default-dynamic-font);

  @media (min-width: 1200px) {
    --app-font-size: 14px
  }
}

html {
  /*
   * For more information on dynamic font scaling, visit the documentation:
   * https://ionicframework.com/docs/layout/dynamic-font-scaling
   */
  --ion-margin: 16px;
  font-size: var(--app-font-size);
}

header {
  display: flex;
  flex-direction: row;
  //align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
}

.formatted-html {
  color: var(--ion-color-dark-shade);
  font-size: medium;
  white-space: pre-line;
  h1, h2, h3, h4, h5, strong {
    font-size: medium;
    font-weight: normal !important;
  }
  ul {
    padding-left: 0;
    margin: 0;
    //list-style-type: none; /* Убираем маркеры списка */
  }
  ul:not(:last-child) {
    margin-bottom: var(--ion-margin);
  }
  li {
    margin-left: var(--ion-margin); /* Отступ перед каждым элементом списка */
    position: relative;
  }

  ol {
    counter-reset: item;
    padding-left: 0;
    li {
      display: block;
      margin-left: 1.7em;
      &::before {
        content: counter(item) ". ";
        counter-increment: item;
        position: absolute;
        margin-left: -1.7em;
      }
    }
  }
}

cdk-virtual-scroll-viewport {
  height: 100%;
  width: 100%;

  .cdk-virtual-scroll-content-wrapper {
    max-width: 100% !important;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.text-first-letter-uppercase {
  text-transform: lowercase;
  &::first-letter {
    text-transform: capitalize;
  }
}

.inner-html {
  strong {
    font-weight: normal !important;
  }
}

.text-truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}

.no-ripple {
  --ripple-color: transparent;
  --background-activated: transparent;
}

.no-pointer {
  pointer-events:none;
}

.signature-pad-canvas {
  border: none !important;
}

