ion-header ion-title {
  font-weight: 500;
  &.multi-line {
    h5 {
      margin-top: 0;
      margin-bottom: 4px;
      font-weight: 500;
    }
    p {
      margin-top: 0;
      margin-bottom: 0;
      font-size: small;
      font-weight: 400;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  @media (min-width: 768px) {
    font-weight: initial;
  }
}
