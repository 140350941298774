$screen-breakpoints: (
  xs: 320px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1920px
) !default;

@each $keySize, $valueSize in $screen-breakpoints {
  [hidden-#{$keySize}] {
    @media (min-width: $valueSize) {
      display: none !important;
    }
  }
}

@each $keySize, $valueSize in $screen-breakpoints {
  [show-#{$keySize}] {
    @media (min-width: $valueSize) {
      display: block;
    }
  }
}

@each $keySize, $valueSize in $screen-breakpoints {
  [show-inline-#{$keySize}] {
    @media (min-width: $valueSize) {
      display: inline-block;
    }
  }
}

@each $keySize, $valueSize in $screen-breakpoints {
  [fix-#{$keySize}] {
    @media (min-width: $valueSize) {
      max-width: $valueSize;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

[side-menu-padding-end] {
  @media (min-width: 992px) {
    --padding-end: var(--app-side-menu-width);
  }
}
