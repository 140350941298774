ion-list {
  border-radius: var(--app-border-radius);
  ion-item:last-of-type {
    --inner-border-width: 0 0 0 0;
  }
}

@media (prefers-color-scheme: dark) {
  .sc-ion-label-md-s>p {
    color: var(--ion-color-step-900, #666666);
  }
}
