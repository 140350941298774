/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

@import "swiper/swiper-bundle.css";

@import 'theme/sf-pro';
@import 'theme/hide-show';
@import "theme/components/ion-button";
@import "theme/components/ion-footer";
@import "theme/components/ion-toolbar";
@import "theme/components/ion-content";
@import "theme/components/ngx-datatable";
@import "theme/components/ion-card";
@import "theme/components/ion-segment";
@import "theme/components/ion-list";
@import "theme/components/ion-accordion";
@import "theme/components/iframe";
@import "theme/components/ion-modal";
@import "theme/components/ion-input";
@import "theme/components/ion-avatar";
@import "theme/components/ion-chip";
@import "theme/components/ion-header";
@import "theme/components/ion-toast";
@import "theme/components/ion-tabs";
